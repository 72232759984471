<template>
    <div class="edit">
        <p class="edit-title">基本信息</p>
        <div class="edit-report">
            <p class="edit-report-title">报告抬头：</p>
            <el-input class="edit-report-content" v-model="reportTitle"></el-input>
        </div>
        <div class="edit-subtitle">
            <p class="edit-subtitle-title">二级标题：</p>
            <el-input class="edit-subtitle-content" v-model="reportSubTitle"></el-input>
        </div>
        <div class="edit-sign">
            <p class="edit-sign-title">默认报告签名：</p>
            <el-switch class="edit-sign-switch" v-model="sopReportSign" active-color="#6DA4D8" inactive-color="#DCDFE6">
            </el-switch>
            <el-input class="edit-sign-name" v-model="sopReportSignValue"></el-input>
        </div>
        <div class="edit-date">
            <p class="edit-date-title">
                默认签名日期：
            </p>
            <el-switch class="edit-date-switch" v-model="sopReportSignDate" active-color="#6DA4D8"
                inactive-color="#DCDFE6">
            </el-switch>
        </div>

        <input-unit class="edit-input" iconKey="睡眠初筛报告Logo" :initialIconURL="reportLogo" inputType="noTitle"
            @onUploadSucceed="onReportLogoUploadSucceed"></input-unit>
        <div class="edit-buttons">
            <div class="edit-buttons-container">
                <button class="edit-buttons-container-cancel" @click="onCancelClick">取消</button>
                <button class="edit-buttons-container-save" @click="onSaveClick">保存</button>
            </div>
        </div>
    </div>
</template>

<script>
import BorderInput from "@c/common/border-input.vue";
import InputUnit from "@c/setting/organization-configuration/edit/input-unit.vue";
export default {
    components: {
        BorderInput,
        InputUnit
    },
    props: {
        model: Object,
    },

    data() {
        return {
            sopReportSign: false,
            sopReportSignDate: false,
            sopReportSignValue: "",
            reportTitle: "",
            reportSubTitle: "",
            reportLogo: "",
        };
    },

    computed: {

    },

    created() {
        this.initValue()
    },

    methods: {
        initValue() {
            if (this.model.sopReportSignDate == "1") {
                this.sopReportSignDate = true;

            } else {
                this.sopReportSignDate = false;

            }

            if (this.model.sopReportSign == null || this.model.sopReportSign == "") {
                this.sopReportSign = false;
                this.sopReportSignValue = "";
            } else {
                this.sopReportSign = true;
                this.sopReportSignValue = this.model.sopReportSign;
            }
            this.reportTitle = this.model.sopReportHeaders
            this.reportSubTitle = this.model.sopReportSubTitle
            this.reportLogo = this.model.sopReportLogo
        },
        onCancelClick() {
            this.$emit("onCancelClick");
        },
        onSaveClick() {

            let signValue = ""

            if (this.sopReportSign == true) {
                signValue = this.sopReportSignValue
            }
            if (this.sopReportSign && signValue == "") {
                this.$toast.showRed('签名不能为空');
                return
            }
            const settingInfoModel = {
                sopReportHeaders: this.reportTitle,
                sopReportSign: signValue,
                sopReportSignDate: this.sopReportSignDate ? "1" : "0",
                sopReportSubTitle: this.reportSubTitle,
                sopReportLogo: this.reportLogo,
            };
            this.$emit("onSaveClick", settingInfoModel);
        },
        onReportLogoUploadSucceed(iconURL) {
            this.reportLogo = iconURL;
        },

    },


};
</script>

<style lang="scss" scoped>
.edit {
    background-color: white;
    width: 100%;
    height: 100%;
    position: relative;

    &-title {
        color: black;
        font-size: rem(16);
        font-weight: bold;
        height: rem(22);
        margin-top: rem(16);
    }

    &-report {
        margin-top: rem(10);
        display: flex;
        align-items: center;

        &-title {
            width: rem(70);
            color: black;
            font-size: rem(14);
        }

        &-content {
            width: rem(286);

            ::v-deep .el-input__inner {
                width: rem(286);
                height: rem(32);
                font-size: rem(14);
                color: black;
            }
        }
    }

    &-subtitle {
        margin-top: rem(10);
        display: flex;
        align-items: center;

        &-title {
            width: rem(70);
            color: black;
            font-size: rem(14);
        }

        &-content {
            width: rem(286);

            ::v-deep .el-input__inner {
                width: rem(286);
                height: rem(32);
                font-size: rem(14);
                color: black;
            }
        }
    }

    &-sign {
        margin-top: rem(23);
        color: black;
        font-size: rem(14);
        display: flex;
        align-items: center;

        &-switch {
            transform: scale(1.3);
            margin-left: rem(6);

        }

        &-name {
            width: rem(286);
            margin-left: rem(16);
            display: flex;
            align-items: center;

            ::v-deep .el-input__inner {
                width: rem(286);
                height: rem(32);
                font-size: rem(14);
                color: black;
            }

        }
    }



    &-date {
        margin-top: rem(23);
        color: black;
        font-size: rem(14);
        display: flex;
        align-items: center;

        &-switch {
            transform: scale(1.3);
            margin-left: rem(6);

        }


    }


    &-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: rem(63);

        &-container {
            display: flex;

            &-cancel {
                color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-save {
                color: white;
                background-color: #325FAC;
                font-size: rem(16);
                border: rem(1) solid #325FAC;
                border-radius: rem(4);
                width: rem(100);
                height: rem(40);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: rem(50);
            }
        }
    }
}
</style>