<template>
  <div class="input-unit">
    <div class="input-unit-title" v-if="inputType != 'noTitle'">
      <p class="input-unit-title-key">{{ titleKey }}：</p>
      <border-input class="input-unit-title-value" :initalValue="initalTitleValue" @onInput="onInputInput"></border-input>
    </div>
    <div v-if="titleKey == '机构睡眠报告抬头名称'" class="input-unit-subtitle">
      <p class="input-unit-subtitle-key">{{ subtitleKey }}：</p>
      <border-input class="input-unit-subtitle-value" :initalValue="initalSubtitleValue" @onInput="onSubInputInput"></border-input>
    </div>
    <upload-unit :iconKey="iconKey" :initialIconURL="initialIconURL" @onUploadSucceed="onUploadSucceed"></upload-unit>
  </div>
</template>

<script>
import BorderInput from "@c/common/border-input.vue";
import UploadUnit from "@c/setting/organization-configuration/edit/upload-unit/upload-unit.vue";

export default {
  components: {
    BorderInput,
    UploadUnit,
  },

  props: {
    titleKey: String,
    subtitleKey:String,
    initalTitleValue: String,
    initalSubtitleValue: String,
    iconKey: String,
    initialIconURL: String,
    inputType:String
  },

  data() {
    return {
      currentTitleValue: "",
      currentSubTitleValue: "",
    };
  },

  created() {
    this.currentTitleValue = this.initalTitleValue;
    this.currentSubTitleValue = this.initalSubtitleValue;
  },

  methods: {
    onInputInput(value) {
      this.currentTitleValue = value;
      this.$emit("onInputInput", this.currentTitleValue);
    },
    onSubInputInput(value){
      console.log("onSubInputInput",value);
      this.currentSubTitleValue = value;
      this.$emit("onSubInputInput", this.currentSubTitleValue);
    },

    onUploadSucceed(iconURL) {
      this.$emit("onUploadSucceed", iconURL);
    }
  },
};
</script>


<style lang="scss" scoped>


.input-unit {
  color: black;
  font-size: rem(14);

  &-title {
    display: flex;
    align-items: center;
    height: rem(32);

    &-key {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &-value {
      width: rem(286);
    }
  }

  &-subtitle {
    margin-top: rem(18);
    display: flex;
    align-items: center;
    height: rem(32);

    &-key {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &-value {
      margin-left: rem(14);
      width: rem(286);
    }
  }
}
</style>